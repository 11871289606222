.anim-beam-network {
    position: relative;
    height: 423px;
    width: 524px;
    margin: 0 auto;
    //background: url(../images/beam-network.png);
    z-index: -1;

    // zoom: 120%;
    // @include media-breakpoint-down(lg) {
    //     zoom: 80%;
    // height: 230px;
    // width: 310px;
    // margin: 0 auto;
    //}

    // span {
    //     @include media-breakpoint-down(lg) {
    //         zoom: 0.6;
    //     }
    // }

    .icon-clouds {
        position: absolute;
        width: 660px;
        height: 522px;
        left: -140px;
        top: -50px;
        background: url(../images/icon-clouds.png);
    }
    .icon-lines {
        position: absolute;
        width: 590px;
        height: 522px;
        left: -141px;
        top: -48px;
        background: url(../images/icon-lines.png);
    }
    .icon-ring {
        position: absolute;
        width: 1180px;
        height: 900px;
        left: -244px;
        top: -83px;
        zoom: 58%;
        background: url(../images/icon-ring.png);
    }
}
