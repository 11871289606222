#pageHome {
    position: relative;
    padding: 0;
    color: black;
    @include media-breakpoint-down(lg) {
        zoom: 80%;
        .row {
            margin: 0;
            padding: 0;
        }
    }

    section.header {
        .logo {
            zoom: 300%;
            @include media-breakpoint-up(lg) {
                margin-left: -18px;
            }
            @include media-breakpoint-down(lg) {
                margin-top: 30px;
            }
        }
        .blue-box {
            position: absolute;
            width: 260px;
            height: 180px;
            left: 96px;
            top: -30px;
            background: #e6ebf5;
            border-radius: 15px;
            z-index: -1;
            @include media-breakpoint-down(lg) {
                top: 60px;
            }
        }
    }

    section.description {
        position: relative;
        @include media-breakpoint-up(lg) {
            height: 950px;
        }

        span.exclamation {
            position: absolute;
            width: 260px;
            height: 267px;
            top: 20px;
            right: 200px;
            background: url(../images/icon-exclamation.png) no-repeat;
        }
        span.iot {
            position: absolute;
            width: 426px;
            height: 268px;
            right: 255px;
            top: 200px;
            background: url(../images/icon-iot.png);
        }
        span.analytics {
            position: absolute;
            width: 426px;
            height: 267px;
            right: 0;
            top: 297px;
            background: url(../images/icon-analytics.png);
            @include media-breakpoint-down(lg) {
                top: -200px;
                left: 0;
                z-index: 1;
            }
        }        
        span.blue-box {
            position: absolute;
            width: 595px;
            height: 683px;
            top: 133px;
            right: 189px;
            background: #508AFA;
            opacity: 0.05;
            border-radius: 15px;
            z-index: -1;
        }

        .top-box {
            top: 210px;
            left: 217px - 48px;
            box-shadow: 10px 10px 10px rgba(72, 81, 98, 0.1), 30px 30px 30px rgba(69, 77, 89, 0.09);
            z-index: 2;
        }
        .bottom-box {
            top: 476px + 133px + 70px;
            right: 165px - 60px;
            border: 2px solid white;
            box-sizing: border-box;
        }
    }

    section.features {
        .icon-place {
            position: absolute;
            width: 260px;
            height: 267px;
            left: 353px - 350px;
            top: -147px;
            background: url(../images/icon-place.png);
        }
        .icon-audio {
            position: absolute;
            width: 250px;
            height: 268px;
            left: 245px - 350px;
            top: -52px;
            background: url(../images/icon-audio.png);
        }
        .icon-exclamation {
            position: absolute;
            width: 260px;
            height: 267px;
            left: 326px - 350px;
            top: 111px;
            background: url(../images/icon-exclamation.png);
        }
        .icon-pay {
            position: absolute;
            width: 250px;
            height: 266px;
            left: 210px - 350px;
            top: 241px;
            background: url(../images/icon-pay.png);
        }
        .icon-incident {
            position: absolute;
            width: 260px;
            height: 268px;
            left: 341px - 350px;
            top: 295px;
            background: url(../images/icon-incident.png);
        }
        .keyword-box {
            padding: 50px;
            border: 2px solid #ffffff;
            filter: drop-shadow(20px 20px 30px rgba(0, 0, 0, 0.05)), drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.05));
            border-radius: 15px;
            margin-right: 20px;
            @include media-breakpoint-up(lg) {
                margin-right: -90px;
            }

            p {
                font-weight: bold;
                font-size: 38px;
            }
        }
        .green-box {
            position: absolute;
            width: 595px;
            height: 683px;
            right: 160px;
            top: -90px;
            background: #40cc52;
            opacity: 0.05;
            border-radius: 15px;
            z-index: -1;
        }
        h2 {
            @include media-breakpoint-down(lg) {
                margin: 60px 70px 30px 70px;
            }
        }
        .text-box {
            padding: 60px;
            border: 2px solid #ffffff;
            box-sizing: border-box;
            box-shadow: 10px 10px 10px rgb(72 81 98 / 10%), 30px 30px 30px rgb(69 77 89 / 9%);
            border-radius: 15px;
            font-size: 22px;
            @include media-breakpoint-down(lg) {
                margin: 20px;
            }
        }
    }

    section.blog {
        h2 {
            font-size: 30px;
        }

        .red-box {
            position: absolute;
            width: 445px;
            height: 500px;
            left: 293px;
            top: 69px;
            background: #ff5f25;
            opacity: 0.05;
            border-radius: 15px;
            @include media-breakpoint-down(lg) {
                right: 0;
                left: auto;
            }
        }
        .carousel-box {
            .carousel-item {
                .carousel-item-image {
                    background: #ffffff;
                    box-shadow: 10px 10px 10px rgba(72, 81, 98, 0.1), 30px 30px 30px rgba(69, 77, 89, 0.09);
                    border-radius: 15px;
                    @include media-breakpoint-down(lg) {
                        height: 60%;
                        overflow: hidden;
                        img {
                            margin-top: -20%;
                        }
                    }
                }
                .carousel-item-text {
                    font-size: 28px;
                    padding: 46px;
                    border: 2px solid #ffffff;
                    box-sizing: border-box;
                    filter: drop-shadow(20px 20px 30px rgba(0, 0, 0, 0.05)),
                        drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.05));
                    border-radius: 15px;
                    @include media-breakpoint-down(lg) {
                        margin-top: -25%;
                    }
                }
            }
            .carousel-item.active,
            .carousel-item-next,
            .carousel-item-prev {
                display: flex;
            }
            $dot-width: 12px;
            .carousel-indicators [data-bs-target] {
                border-radius: $dot-width;
                border-top: $dot-width;
                border-bottom: $dot-width;
                width: $dot-width;
                height: $dot-width;
                margin: $dot-width / 2;
                border: 2px solid #c5d2e9;
                &.active {
                    background: $beam-red;
                }
            }
            .carousel-control-prev-icon {
                background-image: url(../images/icon-prev.svg);
                margin-right: 30px;
            }
            .carousel-control-next-icon {
                background-image: url(../images/icon-next.svg);
            }
            .carousel-control-prev-icon,
            .carousel-control-next-icon {
                background-color: #eff2f7;
                box-shadow: -8px -8px 10px #ffffff, 8px 8px 10px #dfe5ee;
                border-radius: 20px;
                width: 60px;
                height: 60px;
                background-repeat: no-repeat;
                background-size: auto;

                &:hover,
                &:focus {
                    outline: none;
                    box-shadow: -5px -5px 8px #fefefe, 5px 5px 8px #dfe4ee, inset -7px -7px 5px rgba(255, 255, 255, 0.5),
                        inset 7px 7px 5px rgba(176, 179, 195, 0.196323);
                }
            }
        }
    }
}
