#pageIndustries {
    position: relative;
    @include media-breakpoint-down(lg) {
        zoom: 80%;
    }

    section.header {
        position: relative;

        .hero-image {
            position: absolute;
            width: 1137px;
            height: 852px;
            left: -385px;
            top: 50px;
            border-radius: 15px;
            background: linear-gradient(180deg, rgba(239, 242, 247, 0) 62.68%, $beam-background 100%),
                url("../images/industry-public-safety.png");
            background-repeat: no-repeat;
            background-origin: content-box;
            background-attachment: scroll;
            background-position: right top;
            @include media-breakpoint-down(lg) {
                background-size: contain;
                width: 100%;
                left: 0;
                padding: 0;
            }
        }

        h1 {
            font-size: 46px;
            font-weight: bold;
            line-height: 46px;
            padding: 60px;
            position: absolute;
            width: 610px;
            left: 80px;
            top: 105px;
            color: #ffffff;
            border: 3px solid #ffffff;
            box-sizing: border-box;
            filter: drop-shadow(20px 20px 30px rgba(0, 0, 0, 0.05)), drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.05));
            border-radius: 15px;
            @include media-breakpoint-down(lg) {
                zoom: 50%;
                right: 50px;
                left: auto;
                top: 130px;
            }
        }

        .hero-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 60px;
            width: 540px;
            background: #ffffff;
            box-shadow: 10px 10px 10px rgb(72 81 98 / 10%), 30px 30px 30px rgb(69 77 89 / 9%);
            border-radius: 15px;
            transform: translate(120%, 260px);
            @include media-breakpoint-down(lg) {
                transform: none;
                z-index: 2;
                margin: 300px 60px 0 60px;
                width: fit-content;
            }

            span.incident {
                width: 109px;
                height: 118px;
                background: url("../images/icon-incident2.png");
            }

            h4 {
                line-height: 32px;
                margin: 30px 0px;
            }

            p {
                color: #3c3c3c;
            }
        }

        span.red-box {
            width: 342px;
            height: 315px;
            background: #f0ebec;
            border-radius: 15px;
            transform: translate(120%, 180px);
            z-index: -1;
            @include media-breakpoint-down(lg) {
                transform: none;
                margin-top: -150px;
            }
        }
    }

    section.icons {
        position: relative;
        height: 520px;
        z-index: -1;
        margin: 0 auto;

        span.pay {
            position: absolute;
            width: 250px;
            height: 266px;
            left: 0px - 120px;
            top: 0;
            background: url(../images/icon-pay.png);
        }
        span.exclamation {
            position: absolute;
            width: 260px;
            height: 267px;
            left: 340px - 120px;
            top: 16px;

            background: url(../images/icon-exclamation.png);
        }
        span.place {
            position: absolute;
            width: 260px;
            height: 267px;
            left: 150px - 120px;
            top: 130px;

            background: url(../images/icon-place.png);
        }
        span.audio {
            position: absolute;
            width: 250px;
            height: 270px;
            left: -20px - 120px;
            top: 235px;

            background: url(../images/icon-audio.png);
        }
    }

    section.booklet {
        position: relative;

        h2 {
            text-align: center;
            font-weight: bold;
            font-size: 36px;
            color: #000000;
        }

        div.book-item {
            & > div {
                width: 540px;
                margin: 15px 15px;
                @include media-breakpoint-down(lg) {
                    width: auto;
                    margin: 0;
                }

                & > span {
                    display: block;
                    width: 100%;
                    height: 100%;
                    //background: url(../images/industry-girl-helmet.png);
                    background-repeat: no-repeat !important;
                    //background-origin: content-box;
                    background-attachment: scroll !important;
                    background-position: center center !important;
                    background-size: cover !important;
                    border-radius: 15px;
                    box-shadow: 10px 10px 10px rgb(72 81 98 / 10%), 30px 30px 30px rgb(69 77 89 / 9%);
                    @include media-breakpoint-down(lg) {
                        min-height: 360px;
                        border-radius: 0;
                        box-shadow: none;
                    }
                }

                & > div.text {
                    display: block;
                    background: #ffffff;
                    color: #3c3c3c;
                    margin: 0;
                    padding: 60px;
                    border-radius: 15px;
                    box-shadow: 10px 10px 10px rgb(72 81 98 / 10%), 30px 30px 30px rgb(69 77 89 / 9%);
                    @include media-breakpoint-down(lg) {
                        margin: -50px 60px 50px 60px;
                    }
                }

                @mixin iconBoxBackground($image) {
                    background-image: url("../images/" + $image);
                    @include media-breakpoint-down(lg) {
                        background-image: linear-gradient(180deg, rgba(239, 242, 247, 0) 62.68%, #eff2f7 100%),
                            url("../images/" + $image);
                    }
                }

                span.icon-box {
                    &.communication {
                        @include iconBoxBackground("industry-ucc-2.png");
                    }
                    &.transportation {
                        @include iconBoxBackground("industry-transportation.png");
                    }
                    &.merchandising {
                        @include iconBoxBackground("industry-merchandising.jpg");
                    }
                    &.telehealth {
                        @include iconBoxBackground("industry-telehealth.png");
                    }
                    &.logistics {
                        @include iconBoxBackground("industry-logistics.jpg");
                    }
                }
            }
        }

        span.blue-box {
            position: absolute;
            top: -60px;
            width: 530px;
            height: 880px;
            background: #e7edf7;
            transform: translateX(50%);
            border-radius: 15px;
            z-index: -2;
            @include media-breakpoint-down(lg) {
                transform: translateX(-10%);
            }
        }
    }

    section.buttons {
        position: relative;

        .btn {
            margin: 30px 15px 60px 15px;
        }
    }
}
