#pageContact, #page404 {
    margin-top: 50px;
    color: black;

    section.form {
        margin-top: 50px;

        .icons {
            position: relative;

            span {
                z-index: -1;
                @include media-breakpoint-down(lg) {
                    display: none;
                }
            }

            .icon-home {
                position: absolute;
                width: 374px;
                height: 234px;
                left: 100px;
                top: 159px - 160px;
                background: url(../images/icon-home.png);
            }
            .icon-cloud1 {
                position: absolute;
                width: 470px;
                height: 294px;
                left: 23px;
                top: 202px - 160px;
                background: url(../images/icon-cloud.png);
            }
            .icon-incident {
                position: absolute;
                width: 426px;
                height: 268px;
                left: -8px;
                top: 371px - 160px;
                background: url(../images/icon-incident.png);
            }
            .icon-audio {
                position: absolute;
                width: 250px;
                height: 275px;
                left: 109px;
                top: 610px - 160px;
                background: url(../images/icon-audio.png);
            }
            .icon-cloud2 {
                position: absolute;
                width: 380px;
                height: 362px;
                left: 871px - 890px;
                top: 107px - 160px;
                background: url(../images/icon-cloud.png);
            }
            .icon-email {
                position: absolute;
                width: 540px;
                height: 534px;
                left: 698px - 890px;
                top: 78px - 160px;
                background: url(../images/icon-email.png);
            }
            .icon-exclamation {
                position: absolute;
                width: 260px;
                height: 194px;
                left: 890px - 890px;
                top: 499px - 160px;
                background: url(../images/icon-exclamation.png);
            }
        }
        .head-box {
            text-align: center;
            h1 {
                margin: 0;
                font-size: 48px;
                @include media-breakpoint-down(lg) {
                    font-size: 48px * 0.6;
                }
            }
            p {
                font-size: 24px;
                margin: 20px 0px 40px 0px;
                @include media-breakpoint-down(lg) {
                    font-size: 24px * 0.6;
                }
            }
        }
        .form-group {
            input,
            textarea {
                border: 2px solid #c5d2e9;
                border-radius: 15px;
                margin: 20px 0px;
                background-color: transparent;
                padding: 17px 20px;
            }
            &.optinal {
                position: relative;
                &::after {
                    font-size: 11px;
                    color: #91a5c6;
                    content: "Optional";
                    position: absolute;
                    top: 24px;
                    right: 16px;
                }
            }
        }
    }

    section.address {
        position: relative;
        margin-top: 100px;
        min-height: 600px;
        color: black;

        .blue-box {
            position: absolute;
            width: 733px;
            height: 288px;
            left: 300px;
            top: 215px;
            background: #e7edf7;
            border-radius: 15px;
            z-index: -1;
        }
        .text-box {
            max-width: 400px;
            padding: 60px;
            margin: 6px;
            background: #ffffff;
            box-shadow: 10px 10px 10px rgb(72 81 98 / 10%), 30px 30px 30px rgb(69 77 89 / 9%);
            border-radius: 15px;
            @include media-breakpoint-down(lg) {
                max-width: fit-content;
                margin: 0 auto;
                zoom: 0.75;
            }
        }
        h3 {
            font-size: 30px;
        }
        p {
            font-size: 18px;
        }
    }
}
