$font-family-sans-serif: Museo, Roboto, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji" !default;
$enable-negative-margins: true;

@import "node_modules/bootstrap/scss/bootstrap";

.bg-gray-100 {
    background-color: $gray-100 !important;
}
.bg-gray-200 {
    background-color: $gray-200 !important;
}
.bg-gray-300 {
    background-color: $gray-300 !important;
}
.bg-gray-400 {
    background-color: $gray-400 !important;
}
.bg-gray-500 {
    background-color: $gray-500 !important;
}
.bg-gray-600 {
    background-color: $gray-600 !important;
}
.bg-gray-700 {
    background-color: $gray-700 !important;
}
.bg-gray-800 {
    background-color: $gray-800 !important;
}
.bg-gray-900 {
    background-color: $gray-900 !important;
}

.bg-blue-100 {
    background-color: $blue-100 !important;
}
.bg-blue-200 {
    background-color: $blue-200 !important;
}
.bg-blue-300 {
    background-color: $blue-300 !important;
}
.bg-blue-400 {
    background-color: $blue-400 !important;
}
.bg-blue-500 {
    background-color: $blue-500 !important;
}
.bg-blue-600 {
    background-color: $blue-600 !important;
}
.bg-blue-700 {
    background-color: $blue-700 !important;
}
.bg-blue-800 {
    background-color: $blue-800 !important;
}
.bg-blue-900 {
    background-color: $blue-900 !important;
}

@for $i from 4 through 10 {
    .rounded-#{$i} {
        border-radius: ($i/10) * 1rem !important;
    }
}

$whiteSpaces: normal, nowrap, break-spaces, pre, pre-wrap, pre-line;
@each $ws in $whiteSpaces {
  .white-space-#{$ws} {
      white-space: $ws !important;
  }
}