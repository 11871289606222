nav.navbar {
    margin-bottom: 1rem;

    .navbar-nav .nav-link {
        color: #333;
        cursor: pointer;
        &:hover,
        &:focus {
            color: #000;
        }
    }

    .dropdown-toggle::after {
        display: none;
    }

    .navbar-nav-right {
        background: #fff;
        padding: 10px;
        border-radius: 10px;
        box-shadow: 2px 20px 11px -30px #b1abab;
        padding-left: 20px;

        select {
            border: 0;
        }

        li a {
            color: #111;
            text-decoration: none;
        }

        .wrap-drop {
            cursor: pointer;
            position: relative;
            width: 50px;
            z-index: 3;
            margin-right: 10px;

            &::after {
                border-color: #111 transparent;
                border-style: solid;
                border-width: 7px 7px 0;
                content: "";
                height: 0;
                margin-top: -4px;
                position: absolute;
                top: 50%;
                right: 0;
                width: 0;
            }

            .drop {
                background: #fff;
                border-radius: 10px;
                -moz-box-shadow: 2px 20px 20px -10px #b1abab;
                -webkit-box-shadow: 2px 20px 20px -10px #b1abab;
                box-shadow: 2px 20px 20px -10px #b1abab;
                display: none;
                list-style: none;
                opacity: 0;
                pointer-events: none;
                position: absolute;
                top: 100%;
                z-index: 2;
                margin-top: 25px;
                padding: 0;
                left: 50%;
                transform: translate(-50%, 0);
            }

            &.active .drop {
                display: block;
                opacity: 1;
                pointer-events: auto;
            }
        }
    }

    li.nav-item {
        ul.dropdown-menu {
            li {
                & > span {
                    width: 30px;
                    height: 8px;
                    display: block;
                }
                & > a {
                    padding: 10px 30px 10px 20px;
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .beam_cus_nav .navbar-nav .dropdown-menu {
            padding: 10px;
            border: 0;
        }

        ul.dropdown-menu {
            display: block;
        }

        button.navbar-toggler {
            padding-right: 0;
            zoom: 200%;

            .navbar-toggler-icon {
                background-image: url("../images/close.svg");
            }
            &.collapsed .navbar-toggler-icon {
                background-image: url("../images/menu.svg");
            }
            &:focus {
                box-shadow: none !important;
            }
        }

        .landing_communication_inner_hldr > div {
            width: 50%;
        }
        .landing_news_block .new_block_hldr > div {
            margin: 30px;
        }

        .dropdown-menu {
            background: none;
            border: none;
        }
    }

    @include media-breakpoint-up(lg) {
        li.nav-item .dropdown-menu {
            display: none;
            border: 0;
            border-radius: 10px;
            -moz-box-shadow: 2px 20px 20px -10px #b1abab;
            -webkit-box-shadow: 2px 20px 20px -10px #b1abab;
            box-shadow: 2px 20px 20px -10px #b1abab;
            padding: 20px;
            margin-top: 0;
            left: -50%;
            transform: translate(-12%, 0);
        }
        li.nav-item:hover .dropdown-menu {
            display: block;
        }
    }
}
