footer {
    margin-top: 60px;
    text-align: center;

    ul li a {
        color: #000;
        cursor: pointer;
    }

    button.move-to-top {
        border: none;
        display: inline-block; //NR
        cursor: pointer; //NR
        background: #eff2f7;
        border-radius: 20px;
        box-shadow: -8px -8px 10px #ffffff, 8px 8px 10px #dfe5ee;
        background-image: url(../images/icon-top.svg);
        height: 50px;
        width: 50px;
        background-repeat: no-repeat;
        background-position: center;

        &:hover,
        &:focus {
            outline: none;
            box-shadow: -5px -5px 8px #fefefe, 5px 5px 8px #dfe4ee, inset -7px -7px 5px rgb(255 255 255 / 50%),
                inset 7px 7px 5px rgb(176 179 195 / 20%);
        }
    }

    .hide {
        color: $beam-background;
    }

    @include media-breakpoint-down(lg) {
        .footer-menu {
            font-weight: bold;
            margin-top: 30px;
        }
    }
}
