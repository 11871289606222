body {
	background: $beam-background;
	//font-family: "Museo";
	position: relative;
}

$beamColorNames: "red", "yellow", "blue", "purple", "gray", "smoke", "green";
$beamColorValues: $beam-red, $beam-yellow, $beam-blue, $beam-purple, $beam-gray, $beam-smoke, $beam-green;
@each $beamColor in $beamColorNames {
	$i: index($beamColorNames, $beamColor);
	.text-beam-#{$i},
	.text-beam-#{$beamColor} {
		color: nth($beamColorValues, $i) !important;
	}
	.bg-beam-#{$i},
	.bg-beam-#{$beamColor} {
		background-color: nth($beamColorValues, $i) !important;
	}
	.btn-beam-#{$i},
	.btn-beam-#{$beamColor} {
		color: white !important;
		background-color: darken(nth($beamColorValues, $i), 10%) !important;
		border-color: darken(nth($beamColorValues, $i), 10%) !important;
		border-width: 2px !important;
		&:hover {
			background-color: nth($beamColorValues, $i) !important;
			border-color: darken(nth($beamColorValues, $i), 10%) !important;
		}
	}
	.btn-beam-white-#{$i},
	.btn-beam-white-#{$beamColor} {
		color: nth($beamColorValues, $i) !important;
		background-color: white !important;
		border-color: white !important;
		border-width: 2px !important;
		&:hover {
			color: white !important;
			background-color: darken(nth($beamColorValues, $i), 10%) !important;
			border-color: darken(nth($beamColorValues, $i), 10%) !important;
		}
	}
	.btn-beam-whiteline-#{$i},
	.btn-beam-whiteline-#{$beamColor} {
		color: white !important;
		background-color: transparent !important;
		border-color: white !important;
		border-width: 2px !important;
		&:hover {
			color: white !important;
			background-color: darken(nth($beamColorValues, $i), 10%) !important;
			border-color: darken(nth($beamColorValues, $i), 10%) !important;
		}
	}
}

.btn-beam {
	width: 180px;
	padding: 0.9rem 1rem;
	border-radius: 10px;
	box-shadow: -8px -8px 8px #ffffff, 5px 5px 8px #cbd9f0;
	z-index: 1;

	&.btn-lg {
		width: 230px;
	}

	&.btn-sm {
		width: 110px;
		padding: 0.6rem 1rem;
		border-radius: 6px;
	}

	&.btn-primary {
		background: $beam-blue-primary;
		border-color: $beam-blue-primary;
		&:hover {
			background-color: $beam-blue-secondary;
			border-color: $beam-blue-secondary;
		}
	}
	&.btn-outline-success {
		background-color: $beam-background;
		border: 2px solid $beam-green;
		color: $beam-green;
		box-sizing: border-box;
		border-radius: 10px;
		&:hover {
			background-color: $beam-green;
			border-color: $beam-green;
			color: white;
		}
	}

	&.btn-bg-white {
		background-color: white;
	}
}

.beam-text-box {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 60px;
	position: absolute;
	width: 540px;
	border-radius: 15px;
	background: white;

	@include media-breakpoint-down(lg) {
		display: block;
		position: relative !important;
		left: 0 !important;
		right: auto !important;
		top: 0 !important;
		width: fit-content;
		margin: 30px 60px;
	}
}

.dot {
	display: inline-block;
	margin: 0 20px 0 -30px;
	width: 10px;
	height: 10px;
	border-radius: 10px;
}
