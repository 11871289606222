#pageDevelopers {
    position: relative;
    margin-top: 50px;
    padding: 0;
    color: black;
    @include media-breakpoint-down(lg) {
        zoom: 80%;
    }

    section.header {
        margin-top: 60px;

        span.blue-box {
            position: absolute;
            width: 388px;
            height: 227px;
            left: -60px;
            top: -60px;
            background: #508afa;
            opacity: 0.05;
            border-radius: 15px;
        }
        .text-box {
            padding: 60px;
            h1 {
                font-size: 48px;
                margin-bottom: 30px;
            }
            p {
                font-size: 22px;
            }
        }
    }

    section.contact {
        position: relative;
        margin-top: 60px;
        padding-bottom: 135px;

        span.red-box {
            position: absolute;
            width: 392px;
            height: 330px;
            top: 170px;
            left: 60px;
            background: #f0ebec;
            border-radius: 15px;
            z-index: -1;
        }
        .text-box {
            padding: 60px;
            width: 700px;
            background: #ffffff;
            box-shadow: 10px 10px 10px rgb(72 81 98 / 10%), 30px 30px 30px rgb(69 77 89 / 9%);
            border-radius: 15px;
            p {
                font-size: 18px;
            }
        }
    }
}
