#pageProducts {
    position: relative;
    margin-top: 50px;
    padding: 0;

    section.buttons {
        position: relative;

        .btn {
            margin: 30px 15px;
        }
    }

    section.header {
        position: relative;

        span.blue-box {
            position: absolute;
            width: 388px;
            height: 227px;
            left: -60px;
            top: -60px;
            background: #508afa;
            opacity: 0.05;
            border-radius: 15px;
            z-index: -1;
        }
    }

    section.icons {
        position: relative;
        margin-top: 50px;
        @include media-breakpoint-up(lg) {
            height: 950px;
        }

        span.exclamation {
            position: absolute;
            width: 260px;
            height: 267px;
            top: 0;
            right: 167px - 60px;
            background: url(../images/icon-exclamation.png) no-repeat;
        }
        span.pay {
            position: absolute;
            width: 401px;
            height: 266px;
            top: 95px;
            right: 334px - 60px;
            background: url(../images/icon-pay.png) no-repeat;
        }
        span.incident {
            position: absolute;
            width: 109px;
            height: 118px;
            top: 129px;
            right: 103px - 60px;
            margin: 75px 158.5px;
            background: url(../images/icon-incident2.png) no-repeat;
        }
        span.audio {
            position: absolute;
            width: 394px;
            height: 268px;
            top: 569px;
            left: 85px - 48px;
            background: url(../images/icon-audio.png) no-repeat;
            @include media-breakpoint-down(lg) {
                top: 569px - 600px;
            }
        }
        span.place {
            position: absolute;
            width: 260px;
            height: 267px;
            top: 679px;
            left: 298px - 48px;
            background: url(../images/icon-place.png) no-repeat;
            @include media-breakpoint-down(lg) {
                top: 679px - 600px;
            }
        }
        span.red-box {
            position: absolute;
            width: 648px;
            height: 280px;
            top: 168px;
            left: 94px;
            background: #f0ebec;
            border-radius: 15px;
            z-index: -1;
        }
        span.green-box {
            position: absolute;
            width: 659px;
            height: 280px;
            background: #e6f0ef;
            border-radius: 15px;
            top: 266px;
            right: -10px;
            z-index: -1;
        }

        .top-box {
            top: 210px;
            left: 217px - 48px;
            box-shadow: 10px 10px 10px rgba(72, 81, 98, 0.1), 30px 30px 30px rgba(69, 77, 89, 0.09);
            z-index: 2;
            @include media-breakpoint-up(lg) {
                height: 348px;
            }
        }
        .bottom-box {
            top: 384px - 60px;
            right: 165px - 60px;
            border: 2px solid white;
            box-sizing: border-box;
            @include media-breakpoint-up(lg) {
                height: 420px;
            }
        }
        .two-icons {
            @include media-breakpoint-down(lg) {
                position: relative;
                height: 300px;
            }
        }
    }

    section.benefit {
        position: relative;
        margin-top: 50px;
        @include media-breakpoint-up(lg) {
            height: 950px;
        }

        div.benefit-box {
            width: 515px;
            height: 478px;
            left: 0px;
            top: 0px;
            background: transparent;
            border: 3px solid white;
            box-sizing: border-box;
            filter: drop-shadow(10px 10px 10px rgba(72, 81, 98, 0.1)),
                drop-shadow(30px 30px 30px rgba(69, 77, 89, 0.09));

            p {
                font-size: 21px;
                margin-right: 15px;
            }
        }
        div.benefit-list {
            padding: 60px 60px 60px 90px;
            background: #ffffff;
            box-shadow: 10px 10px 10px rgb(72 81 98 / 10%), 30px 30px 30px rgb(69 77 89 / 9%);
            border-radius: 15px;
            @include media-breakpoint-up(lg) {
                position: absolute;
                width: 665px;
                height: 780px;
                left: 447px;
                top: 68px;
            }
            @include media-breakpoint-down(lg) {
                margin: 30px 0;
            }
        }
    }
}
