.anim-cloud-point {
    position: relative;
    z-index: -1;
    height: 400px;
    @include media-breakpoint-down(lg) {
        height: 230px;
        width: 310px;
        margin: 0 auto;
    }

    span {
        @include media-breakpoint-down(lg) {
            zoom: 0.6;
        }
    }

    .icon-clouds {
        position: absolute;
        width: 660px;
        height: 522px;
        left: -180px;
        top: -10px;
        background: url(../images/icon-clouds.png);
    }
    .icon-lines {
        position: absolute;
        width: 590px;
        height: 522px;
        left: -33px;
        top: -115px;
        background: url(../images/icon-lines.png);
    }
    .icon-place {
        position: absolute;
        width: 500px;
        height: 523px;
        left: -215px;
        top: -10px;
        background: url(../images/icon-place2.png);
    }
}
