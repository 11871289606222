.anim-handshake {
    position: relative;
    display: block;
    height: 360px;
    z-index: -1;
    @include media-breakpoint-down(lg) {
        width: 560px;
        margin: 0 auto;
        zoom: 80%;
    }

    .image-layer-clouds {
        position: absolute;
        width: 660px;
        height: 522px;
        left: -100px;
        top: -80px;
        background: url(../images/icon-clouds.png);
    }
    .image-layer-lines {
        position: absolute;
        width: 550px;
        height: 522px;
        left: -275px;
        top: -137px;
        background: url(../images/icon-lines.png);
        animation-duration: 6s;
        animation-name: slidein-lines;
        animation-iteration-count: infinite;
    }
    .image-layer-hands {
        position: absolute;
        width: 600px;
        height: 523px;
        left: -137px;
        top: -102px;
        background: url(../images/icon-hands.png);
        animation-duration: 6s;
        animation-name: slidein-hands;
        animation-iteration-count: infinite;
    }

    @keyframes slidein-lines {
        50% {
            left: -295px;
        }
        100% {
            left: -275px;
        }
    }

    @keyframes slidein-hands {
        50% {
            top: -80px;
        }
        100% {
            top: -102px;
        }
    }
}
