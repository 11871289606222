// @font-face {
//     font-family: 'Museo';
//     // src: url('../fonts/museo100-regular.eot');
//     // src: url('../fonts/museo100-regular.eot?#iefix') format('embedded-opentype'),
//     //     url('../fonts/museo100-regular.woff2') format('woff2'),
//     //     url('../fonts/museo100-regular.woff') format('woff'),
//     //     url('../fonts/museo100-regular.ttf') format('truetype'),
//     //     url('../fonts/museo100-regular.svg#Museo100-Regular') format('svg');
//     src: url('../fonts/museo100-regular.otf');
//     font-weight: 100;
//     font-style: normal;
//     font-display: swap;
// }

@font-face {
    font-family: 'Museo';
    // src: url('../fonts/museo300-regular.eot');
    src: // url('../fonts/museo300-regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/museo300-regular-webfont.woff2') format('woff2'),
         url('../fonts/museo300-regular-webfont.woff') format('woff'),
         url('../fonts/museo300-regular-webfont.ttf') format('truetype');
        // url('../fonts/museo300-regular.svg#Museo300-Regular') format('svg');
    //src: url('../fonts/museo300-regular.otf');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

// @font-face {
//     font-family: 'Museo';
//     // src: url('../fonts/museo500-regular.eot');
//     // src: url('../fonts/museo500-regular.eot?#iefix') format('embedded-opentype'),
//     //     url('../fonts/museo500-regular.woff2') format('woff2'),
//     //     url('../fonts/museo500-regular.woff') format('woff'),
//     //     url('../fonts/museo500-regular.ttf') format('truetype'),
//     //     url('../fonts/museo500-regular.svg#Museo500-Regular') format('svg');
//     src: url('../fonts/museo500-regular.otf');
//     font-weight: 500;
//     font-style: normal;
//     font-display: swap;
// }

@font-face {
    font-family: 'Museo';
    src: url('../fonts/museo700-regular.eot');
    src: url('../fonts/museo700-regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museo700-regular-webfont.woff2') format('woff2'),
        url('../fonts/museo700-regular-webfont.woff') format('woff'),
        url('../fonts/museo700-regular-webfont.ttf') format('truetype'),
        url('../fonts/museo700-regular.svg#Museo700-Regular') format('svg');
    //src: url('../fonts/museo700-regular.otf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

//@font-face {
//    font-family: 'Museo';
//    // src: url('../fonts/museo900-regular.eot');
//    // src: url('../fonts/museo900-regular.eot?#iefix') format('embedded-opentype'),
//    //     url('../fonts/museo900-regular.woff2') format('woff2'),
//    //     url('../fonts/museo900-regular.woff') format('woff'),
//    //     url('../fonts/museo900-regular.ttf') format('truetype'),
//    //     url('../fonts/museo900-regular.svg#Museo900-Regular') format('svg');
//    src: url('../fonts/museo900-regular.otf');
//    font-weight: 900;
//    font-style: normal;
//    font-display: swap;
//}
