// Beam Colores
$beam-background: #eff2f7;
$beam-red: #f26130;
$beam-yellow: #faad1c;
$beam-blue: #5ba2d8;
$beam-purple: #463bbd;
$beam-gray: #818285;
$beam-smoke: #a7a7a7;
$beam-green: #56bb55;

// Beam - Other colores
$beam-blue-primary: #508afa;
$beam-blue-secondary: shade-color($beam-blue-primary, 10);
$beam-orange-primary: #ff5f25;
