#pageAbout {
    position: relative;
    padding: 0;
    color: black;

    .text-box {
        padding: 60px;
        border: 2px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 10px 10px 10px rgb(72 81 98 / 10%), 30px 30px 30px rgb(69 77 89 / 9%);
        border-radius: 15px;
    }

    section.header {
        position: relative;
        min-height: 800px;

        span.orange-box {
            position: absolute;
            width: 281px;
            height: 238px;
            left: -41px;
            top: -35px;
            background: #fbae16;
            opacity: 0.05;
            border-radius: 15px;
        }
        .text-box {
            border: none;
            box-shadow: none;
            position: relative;
            width: 586px;
            margin-top: 60px;
            @include media-breakpoint-up(lg) {
                margin-left: 95px;
            }
            h1 {
                font-size: 48px;
                line-height: 48px;
                margin-bottom: 30px;
            }
            p {
                font-size: 24px;
            }
        }
        .anim-beam-network {
            margin: 170px auto 0 auto;
        }
    }

    section.description {
        position: relative;
        @include media-breakpoint-up(lg) {
            min-height: 900px;
        }

        span.green-box {
            position: absolute;
            height: 120%;
            width: 332px;
            left: 603px;
            top: -16px;
            background: #e6f0ef;
            border-radius: 15px;
            z-index: -1;
            @include media-breakpoint-down(lg) {
                left: 75px;
                top: 0;
            }
        }

        .text-box {
            position: absolute;
            width: 550px;
            right: 117px;
            @include media-breakpoint-down(lg) {
                position: relative;
                margin: 40px;
                width: fit-content;
                right: auto;
                height: auto;
            }

            p {
                font-size: 18px;
                color: #3c3c3c;
            }
        }
        span.icon-place {
            position: absolute;
            width: 260px;
            height: 268px;
            left: 109px;
            top: -79px;
            background: url(../images/icon-place.png);
        }
        span.icon-exclamation {
            position: absolute;
            width: 260px;
            height: 267px;
            left: 321px;
            top: 22px;
            background: url(../images/icon-exclamation.png);
            @include media-breakpoint-down(lg) {
                position: relative;
                left: 170px;
                top: 0;
            }
        }
        span.icon-iot {
            position: absolute;
            width: 426px;
            height: 268px;
            left: 162px;
            top: 156px;

            background: url(../images/icon-iot.png);
        }
    }

    section.features {
        position: relative;
        @include media-breakpoint-up(lg) {
            min-height: 750px;
        }

        .red-box {
            position: absolute;
            width: 791px;
            height: 402px;
            left: 197px;
            top: 210px;
            background: #f0ebec;
            border-radius: 15px;
            @include media-breakpoint-down(lg) {
                right: 0px;
                left: auto;
            }
        }
        .text-box {
            position: absolute;
            width: 575px;
            left: 260px;
            top: 20px;
            @include media-breakpoint-down(lg) {
                position: relative;
                margin: 20px;
                width: fit-content;
                height: auto;
                left: auto;
            }
            h2 {
                font-size: 38px;
                margin-bottom: 30px;
                @include media-breakpoint-down(lg) {
                    font-size: 36px;
                }
            }
            p {
                font-size: 18px;
                color: #3c3c3c;
                margin: 10px 20px 0px 40px;
            }
        }
        .icon-analytics {
            position: absolute;
            width: 426px;
            height: 267px;
            left: 936px;
            top: 21px;
            background: url(../images/icon-analytics.png);
            @include media-breakpoint-down(lg) {
                top: -200px;
                left: 0;
                z-index: 1;
            }
        }
        .icon-incident {
            position: absolute;
            width: 426px;
            height: 266px;
            left: 821px;
            top: 237px;

            background: url(../images/icon-incident.png);
        }
    }

    section.boxes {
        position: relative;
        @include media-breakpoint-up(lg) {
            min-height: 600px;
        }
        color: white;

        .box-icon {
            position: relative;
            display: flex;
            flex-direction: column;
            padding: 60px;
            box-shadow: 10px 10px 10px rgb(72 81 98 / 10%), 30px 30px 30px rgb(69 77 89 / 9%);
            border-radius: 15px;
            flex: none;
            height: 500px;
            width: 320px;
            margin-left: 60px;
            @include media-breakpoint-down(lg) {
                height: fit-content;
                font-size: 12px;
                padding: 15px 20px 0px 20px;
                margin: 40px;
                width: 60%;
            }
            span {
                @include media-breakpoint-down(lg) {
                    zoom: 60%;
                    margin-bottom: 10px !important;
                }
            }
            h3 {
                font-size: 30px;
                margin-bottom: 30px;
                @include media-breakpoint-down(lg) {
                    font-size: 24px;
                    margin-bottom: 10px;
                }
            }
            &.box-admin {
                background: $beam-blue-primary;
                span {
                    width: 115px;
                    height: 126px;
                    margin-bottom: 160px - 126px;
                    background: url(../images/icon-iot2.png);
                }
            }
            &.box-live {
                background: $beam-purple;
                span {
                    width: 109px;
                    height: 118px;
                    margin-bottom: 160px - 118px;
                    background: url(../images/icon-incident2.png);
                }
            }
            &.box-analytics {
                background: $beam-orange-primary;
                span {
                    width: 115px;
                    height: 120px;
                    margin-bottom: 160px - 120px;
                    background: url(../images/icon-analytics2.png);
                }
            }
        }
        .arrow {
            position: absolute;
            width: 20px;
            height: 14px;
            right: -70px;
            top: calc(50% - 14px / 2);
            background: url(../images/next.png);
            @include media-breakpoint-down(lg) {
                position: relative;
                display: block;
                transform: rotate(90deg);
                left: 50%;
            }
        }
    }

    section.different {
        position: relative;
        @include media-breakpoint-up(lg) {
            min-height: 700px;
        }

        h2 {
            text-align: center;
        }
        span.blue-box {
            position: absolute;
            width: 440px;
            height: 480px;
            left: calc(50% - (440px / 2) - 40px);
            top: 79px;
            background: $beam-blue-primary;
            opacity: 0.05;
            border-radius: 15px;
        }
        .text-box {
            &.text-box-description {
                position: absolute;
                width: 479px;
                height: 328px;
                left: 150px;
                top: 137px;
                @include media-breakpoint-down(lg) {
                    position: relative;
                    width: 60%;
                    height: fit-content;
                    left: auto;
                    top: auto;
                    margin: 30px auto;
                }
            }
            &.text-box-items {
                position: absolute;
                width: 556px;
                height: 352px;
                left: 680px;
                top: 222px;
                @include media-breakpoint-down(lg) {
                    position: relative;
                    width: 80%;
                    height: fit-content;
                    left: auto;
                    top: auto;
                    margin: 30px auto 130px auto;
                }
                p {
                    margin: 10px 20px 0px 40px;
                }
            }
        }
    }

    section.buttons {
        position: relative;

        .btn {
            margin: 30px 15px 60px 15px;
        }
    }
}
